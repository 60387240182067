import { FC, useEffect, useState } from 'react'

import theme from '../../styles/themes'
import { request } from '../../utils/fetcher'

import Layout from '../../components/Layout'
import { Box, LinearProgress, Paper } from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'

import { BarChart, PieChart } from '@mui/x-charts'

const Graphs = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [charts, setCharts] = useState<any[]>([])
  const chartsList = ['newChats']

  useEffect(() => {
    setCharts([])
    setLoading(true)
    getAllCharts()
  }, [])

  useEffect(() => {
    console.log(charts)

    if (charts.length === chartsList.length) setLoading(false)
  }, [charts])

  const getAllCharts = () => chartsList.forEach((chart) => getSingleChart(chart))

  const getSingleChart = async (chart: string) => {
    const data = await request('company/graphs', { type: chart, filters: [] })
    if (data.success && data.chart !== null) setCharts((prev) => [...prev, data.chart])
    else setCharts((prev) => [...prev, { type: null }])
  }

  const getChartComponent = (type: string, data: any) => {
    console.log(Object.keys(data).map((key) => data[key]))
    switch (type) {
      case 'bar':
        return (
          <BarChart
            slotProps={{ legend: { hidden: true } }}
            xAxis={[{ scaleType: 'band', data: [''] }]}
            series={Object.keys(data).map((key) => {
              return { data: [data[key]], label: key }
            })}
            width={500}
            height={300}
          />
        )

      default:
        return null
    }
  }

  return (
    <div
      style={{
        overflow: 'auto',
      }}
    >
      <Layout>
        {loading && <LinearProgress />}
        <Box
          sx={{
            maxWidth: '100%',
            margin: '20px',
          }}
        >
          <h4
            style={{
              padding: '0 20px',
              margin: '0 0 20px 0',
              fontSize: 16,
              fontWeight: '400',
              color: theme.colors.primary,
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <BarChartIcon /> Gráficos
          </h4>
          <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', paddingBottom: '50px' }}>
            {charts.map((chart, index) => (
              <Paper key={index} sx={{ padding: '20px 10px' }}>
                {getChartComponent(chart.type, chart.data)}
              </Paper>
            ))}
          </div>
        </Box>
      </Layout>
    </div>
  )
}

export default Graphs
